import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Meta from '../components/meta'
import ProductInfo from '../components/product/ProductInfo'
import ItemSummary from '../components/product/item-summary'
import Series from '../components/product/Series'
import WhichModel from '../components/which-model'
import filterLocale from '../components/utils/filter-locale'
import localeCheck from '../components/utils/locale-check'
import getCurrency from '../components/utils/currency'

const isBrowser = typeof window !== 'undefined'

const ProductPageTemplate = ({
	data: { data, lastBanner, xl, l, md, sm, xs, xxs, zoom },
	pageContext,
}) => {
	const { locale, defaultLocale } = pageContext
	/** Filter by locale */
	const [{ node }] = filterLocale(data.edges, locale)
	const [{ node: filteredLastBanner }] = filterLocale(lastBanner.edges, locale)
	const [{ node: extraLarge }] = filterLocale(xl.edges, locale)
	const [{ node: largeImage }] = filterLocale(l.edges, locale)
	const [{ node: medImage }] = filterLocale(md.edges, locale)
	const [{ node: smallImage }] = filterLocale(sm.edges, locale)
	const [{ node: extraSmallImage }] = filterLocale(xs.edges, locale)
	const [{ node: xxSmallImage }] = filterLocale(xxs.edges, locale)
	const [{ node: zoomImage }] = filterLocale(zoom.edges, locale)

	/** Desconstruct backgrounds */
	const { seriesPage: xlarge } = extraLarge
	const { seriesPage: large } = largeImage
	const { seriesPage: med } = medImage
	const { seriesPage: small } = smallImage
	const { seriesPage: xsmall } = extraSmallImage
	const { seriesPage: xxsmall } = xxSmallImage
	const {
		slug,
		seoTitle,
		seoKeywords,
		seoDescription,
		images,
		brand,
		series,
		model,
		categories,
		price,
		sku,
		shippingClass,
		body,
		video,
		seriesPage,
		features,
		specifications,
		brochure,
		manual,
		msds,
		warrantyDownload,
		distinction,
		tags,
		brandPage,
		comparisonItem,
		degreeView,
		active,
		meta,
		inStock,
		linkTo,
		shouldIndex
	} = node

	useEffect(() => {
		/**
		 * Google Analytics - Tag Manager - Enhanced Ecommerce
		 * Measuring product impressions for every search result change
		 * */
		const floatPrice = price =>
			price >= 1 ? parseFloat(price).toFixed(2) : parseFloat(0).toFixed(2)
		let product = {
			name: node.seoTitle, // the name of the product, including Title and Subtitle
			id: node.sku, // product serial number
			price: Number(floatPrice(node.price)), // actual price per unit or in case of fixed price discounts the discounted price
			brand: node.brand, // product brand
			category: [node.categories && node.categories.title].toString(), // Dolphin domestic, Dolphin commercial, mineral swim, ozone swim
		}
		if (isBrowser && window.dataLayer) {
			window.dataLayer.push({
				event: 'productDetails',
				ecommerce: {
					detail: {
						actionField: { list: 'Category List' }, // 'detail' actions have an optional list property.
						products: [product],
					},
				},
			})
		}
	}, [node])

	const checkedLocale = localeCheck(locale, defaultLocale)
	const currency = getCurrency(locale)

	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
				lang={locale}
				shouldIndex={shouldIndex !== null ? shouldIndex : true}
			>
				{meta?.map(x => (
					<link rel="alternate" href={x.url} hrefLang={x.lang} key={x.url} />
				))}
			</Meta>
			<ProductInfo
				imageArray={images != null ? images : []}
				brand={brand}
				series={series}
				model={model}
				price={price}
				sku={sku}
				categories={categories}
				shippingClass={shippingClass}
				slug={slug}
				val={1}
				feature={features}
				specifications={specifications}
				brochure={brochure}
				manual={manual}
				msds={msds}
				warrantyDownload={warrantyDownload}
				distinction={distinction}
				tags={tags}
				brandPage={brandPage}
				title={seoTitle}
				comparisonitem={comparisonItem}
				seriesPageDetails={seriesPage}
				degreeView={degreeView}
				zoom={zoomImage}
				active={active}
				instock={inStock}
				linkto={linkTo}
				checkedLocale={checkedLocale}
				currency={currency}
				locale={locale}
			/>
			<ItemSummary
				content={body}
				vid={video}
				brand={brand}
				tags={tags}
				brandPage={brandPage}
				comparisonitem={comparisonItem}
				title={seoTitle}
				active={active}
				series={series}
				model={model}
				categories={categories}
				checkedLocale={checkedLocale}
				locale={locale}
			/>
			{brand === 'Dolphin' && (
				<Series
					series={series}
					model={model}
					locale={locale}
					seriesPageDetails={seriesPage}
					brandPage={brandPage}
					xlarge={xlarge}
					large={large}
					medium={med}
					small={small}
					xsmall={xsmall}
					xxsmall={xxsmall}
					slug={slug}
					checkedLocale={checkedLocale}
				/>
			)}
			{brand === 'Dolphin' && (
				<WhichModel data={filteredLastBanner} checkedLocale={checkedLocale} />
			)}
		</Layout>
	)
}

export default ProductPageTemplate

export const ProductPageQuery = graphql`
	query ProductPageBySlug($slug: String!) {
		data: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					slug
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					price
					sku
					inStock
					distinction
					tags
					comparisonItem
					active
					shouldIndex
					linkTo {
						slug
						model
					}
					meta {
						url
						lang
					}
					brandPage {
						slug
					}
					images {
						id
						description
						fluid(quality: 60, maxWidth: 450, maxHeight: 450) {
							src
							srcWebp
						}
					}
					degreeView
					brand
					model
					series
					categories {
						title: seoTitle
						slug
					}
					shippingClass
					body {
						json
					}
					video
					specifications {
						spec
						detail
						details
					}
					frequentlyAskedQuestions {
						title
						description {
							description
						}
					}
					brochure {
						file {
							url
						}
					}
					manual {
						file {
							url
						}
					}
					msds {
						file {
							url
						}
					}
					warrantyDownload {
						file {
							url
						}
					}
					features {
						title
						description {
							description
						}
						tooltip
						slug
						faqCategory
					}
					seriesPage {
						seoDescription
						slug
						media {
							file {
								url
							}
							description
						}

						backgroundImage {
							fluid(
								quality: 80
								maxWidth: 1920
								maxHeight: 800
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
						banner {
							description {
								json
							}

							backgroundImage {
								fluid(
									quality: 80
									maxWidth: 1920
									maxHeight: 800
									cropFocus: CENTER
									resizingBehavior: FILL
								) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		zoom: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					slug
					node_locale
					images {
						id
						description
						fluid(quality: 60, maxWidth: 800, maxHeight: 800) {
							src
							srcWebp
						}
					}
				}
			}
		}
		xl: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(
								quality: 80
								maxWidth: 1699
								maxHeight: 800
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(
									quality: 80
									maxWidth: 1699
									maxHeight: 800
									cropFocus: CENTER
									resizingBehavior: FILL
								) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		l: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(
								quality: 80
								maxWidth: 1399
								maxHeight: 700
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(
									quality: 80
									maxWidth: 1399
									maxHeight: 700
									cropFocus: CENTER
									resizingBehavior: FILL
								) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		md: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(
								quality: 80
								maxWidth: 1279
								maxHeight: 600
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(
									quality: 80
									maxWidth: 1279
									maxHeight: 600
									cropFocus: CENTER
									resizingBehavior: FILL
								) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		sm: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(
								quality: 80
								maxWidth: 1023
								maxHeight: 500
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(
									quality: 80
									maxWidth: 1023
									maxHeight: 500
									cropFocus: CENTER
									resizingBehavior: FILL
								) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}

		xs: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(
								quality: 80
								maxWidth: 767
								maxHeight: 350
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(
									quality: 80
									maxWidth: 767
									maxHeight: 350
									cropFocus: CENTER
									resizingBehavior: FILL
								) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		xxs: allContentfulProduct(filter: { slug: { eq: $slug } }) {
			edges {
				node {
					node_locale
					seriesPage {
						backgroundImage {
							fluid(
								quality: 80
								maxWidth: 524
								maxHeight: 350
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}

						banner {
							backgroundImage {
								fluid(
									quality: 80
									maxWidth: 524
									maxHeight: 350
									cropFocus: CENTER
									resizingBehavior: FILL
								) {
									src
									srcWebp
								}
							}
						}
					}
				}
			}
		}
		lastBanner: allContentfulBanner(
			filter: { contentful_id: { eq: "5UBkM4QSv0bHOcKjyMBmw1" } }
		) {
			edges {
				node {
					hook
					node_locale
					mainActionText
					mainActionUrl
					description {
						json
					}
					backgroundImage {
						file {
							url
						}
					}
				}
			}
		}
	}
`
ProductPageTemplate.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.objectOf({
		prodslug: PropTypes.string,
		locale: PropTypes.string,
		defaultLocale: PropTypes.string,
	}),
}
